/**
 * typography.scss
 */
/*
  settings.scss
  ------------------------------------------------------------

  This document is intended to carry variables, function,
  mixins and similar for other components to import.

  Only completely silent objects are allowed in here.
*/
/**
 * Global variables
 */
/*
  transitions.scss
  ------------------------------------------------------------

  Setup timing functions and transition durations
*/
h1,
.h1, h2,
.h2, h3,
.h3, h4,
.h4, h5,
.h5, h6,
.h6 {
  margin: 0;
  color: false; }
  h1:not(:first-child),
  .h1:not(:first-child), h2:not(:first-child),
  .h2:not(:first-child), h3:not(:first-child),
  .h3:not(:first-child), h4:not(:first-child),
  .h4:not(:first-child), h5:not(:first-child),
  .h5:not(:first-child), h6:not(:first-child),
  .h6:not(:first-child) {
    margin-top: 4.8rem; }

h1,
.h1, h2,
.h2 {
  font: 300 1em/1.4 "ResursSans-Display", Arial, Sans-serif;
  margin-bottom: -0.185em; }

h1,
.h1, h2,
.h2, h3,
.h3, h4,
.h4 {
  font-weight: 800; }

h4,
.h4, h5,
.h5, h6,
.h6 {
  font-family: "ResursSans", Arial, Sans-serif;
  line-height: 1.5; }

h1,
.h1 {
  font-size: 2em; }
  @media (max-width: 599px) {
    h1,
    .h1 {
      font-size: 1.15em; } }
  @media (min-width: 600px) and (max-width: 1279px) {
    h1,
    .h1 {
      font-size: 1.5em; } }

h2,
.h2 {
  font-size: 1.5em; }
  @media (max-width: 599px) {
    h2,
    .h2 {
      font-size: 1.15em; } }

h3,
.h3 {
  font-size: 1.15em; }

h4,
.h4 {
  font-size: 1em; }

h5,
.h5 {
  font-size: 1em; }

h6,
.h6 {
  font-size: 1em; }

.subheading {
  display: block;
  color: #a3acc2;
  transition: color 250ms; }
  .wf-loading .subheading {
    color: transparent !important; }

p > a,
p > i > a,
p > b > a,
p > em > a,
p > strong > a, .typography ol > li > a, .typography ul > li > a {
  border-bottom: 1px solid currentColor; }
  p > a:focus,
  p > i > a:focus,
  p > b > a:focus,
  p > em > a:focus,
  p > strong > a:focus, .typography ol > li > a:focus, .typography ul > li > a:focus, p > a:hover,
  p > i > a:hover,
  p > b > a:hover,
  p > em > a:hover,
  p > strong > a:hover, .typography ol > li > a:hover, .typography ul > li > a:hover {
    color: #00b5e2; }

p {
  line-height: 1.5;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }
  p:first-child {
    margin-top: 0; }
  p:last-child {
    margin-bottom: 0; }

mark {
  padding: .2em;
  background: #fff6db; }

strong, b {
  font-weight: 800;
  color: black;
  transition: color 250ms; }
  .wf-loading strong, .wf-loading b {
    color: transparent !important; }

.typography {
  line-height: 1.5;
  max-width: 80ch; }
  .typography ol, .typography ul {
    line-height: 1.5;
    padding-left: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    .typography ol:first-child, .typography ul:first-child {
      margin-top: 0; }
    .typography ol:last-child, .typography ul:last-child {
      margin-bottom: 0; }
    .typography ol > li, .typography ul > li {
      padding-left: 0.5rem;
      margin-top: 1.25rem; }
    .typography ol ul, .typography ul ul {
      list-style-type: circle; }
  .typography ul {
    list-style-type: disc;
    padding-left: 2.5rem; }
  .typography ol {
    list-style-type: decimal; }
  .typography [style*="font-size"] {
    font-size: inherit !important; }

.lead {
  font-size: 1.25em; }
