/*
  settings.scss
  ------------------------------------------------------------

  This document is intended to carry variables, function,
  mixins and similar for other components to import.

  Only completely silent objects are allowed in here.
*/
/**
 * Global variables
 */
/*
  transitions.scss
  ------------------------------------------------------------

  Setup timing functions and transition durations
*/
:export {
  stutter: 20; }

.slideUp, .slideUpFull, .slideDown, .slideLeft, .slideLeftFull, .slideRightOutFull, .slideRight, .fadeIn {
  opacity: 0;
  animation-duration: 800ms;
  animation-fill-mode: forwards; }
  :global(.no-cssanimations) .slideUp, :global(.no-cssanimations) .slideUpFull, :global(.no-cssanimations) .slideDown, :global(.no-cssanimations) .slideLeft, :global(.no-cssanimations) .slideLeftFull, :global(.no-cssanimations) .slideRightOutFull, :global(.no-cssanimations) .slideRight, :global(.no-cssanimations) .fadeIn {
    opacity: 1; }

.slideDownOut, .slideRightOut, .fadeOut {
  animation-duration: 800ms;
  animation-fill-mode: forwards; }

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(1rem); }
  100% {
    opacity: 1; } }

.slideUp {
  animation-name: slideUp; }

@keyframes slideUpFull {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1; } }

.slideUpFull {
  animation-name: slideUpFull; }

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-1rem); }
  100% {
    opacity: 1; } }

.slideDown {
  animation-name: slideDown; }

@keyframes slideDownOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateY(5rem); } }

.slideDownOut {
  animation-name: slideDownOut;
  animation-duration: 400ms; }

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(1rem); }
  100% {
    opacity: 1; } }

.slideLeft {
  animation-name: slideLeft; }

@keyframes slideLeftFull {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1; } }

.slideLeftFull {
  animation-name: slideLeftFull;
  animation-timing-function: cubic-bezier(0.1, 1, 0.55, 1);
  animation-duration: 400ms; }

@keyframes slideRightOutFull {
  0% {
    opacity: 1;
    transform: translateX(0%); }
  100% {
    opacity: 0;
    transform: translateX(100%); } }

.slideRightOutFull {
  animation-name: slideRightOutFull;
  animation-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  animation-duration: 400ms; }

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1; } }

.slideRight {
  animation-name: slideRight; }

@keyframes slideRightOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateX(5rem); } }

.slideRightOut {
  animation-name: slideRightOut; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

.short {
  animation-duration: 250ms; }

.slow {
  animation-duration: 800ms; }

.paused {
  animation-play-state: paused; }
