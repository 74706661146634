/*
  settings.scss
  ------------------------------------------------------------

  This document is intended to carry variables, function,
  mixins and similar for other components to import.

  Only completely silent objects are allowed in here.
*/
/**
 * Global variables
 */
/*
  transitions.scss
  ------------------------------------------------------------

  Setup timing functions and transition durations
*/
.checkoutSuccess {
  width: 100%;
  max-height: 100%;
  overflow-y: auto; }

.container {
  min-width: 50%;
  display: inline-block; }

.headerContainer {
  margin-top: 2.5rem;
  display: flex; }

.header {
  word-wrap: none;
  white-space: nowrap; }

.successIcon {
  width: 36px;
  min-width: 36px;
  margin-right: 1.5rem; }

.info {
  margin-top: 2.5rem; }

.row {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem; }
  .row h3 {
    font-family: 'ResursSans-Display';
    vertical-align: bottom;
    line-height: 1.2;
    font-size: 1.25em;
    word-wrap: none;
    white-space: nowrap; }
  .row span {
    vertical-align: bottom;
    line-height: 1.2;
    font-size: 1.25em;
    white-space: nowrap;
    word-wrap: none;
    margin-left: 5em; }

.orderNumberContainer {
  white-space: nowrap; }

.copyButton {
  margin-left: 0.5rem; }
