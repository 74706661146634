/*
  settings.scss
  ------------------------------------------------------------

  This document is intended to carry variables, function,
  mixins and similar for other components to import.

  Only completely silent objects are allowed in here.
*/
/**
 * Global variables
 */
/*
  transitions.scss
  ------------------------------------------------------------

  Setup timing functions and transition durations
*/
.row--mini {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
  .row--mini:first-child {
    margin-top: 0; }
  .row--mini:last-child {
    margin-bottom: 0; }

.row--small {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  .row--small:first-child {
    margin-top: 0; }
  .row--small:last-child {
    margin-bottom: 0; }

.row {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  .row:first-child {
    margin-top: 0; }
  .row:last-child {
    margin-bottom: 0; }

.row--large {
  margin-top: 4rem;
  margin-bottom: 4rem; }
  .row--large:first-child {
    margin-top: 0; }
  .row--large:last-child {
    margin-bottom: 0; }

.row--huge {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .row--huge:first-child {
    margin-top: 0; }
  .row--huge:last-child {
    margin-bottom: 0; }

.row--section:first-child {
  margin-top: 0; }

.row--section:last-child {
  margin-bottom: 0; }

.row--input {
  margin-top: 3rem;
  margin-bottom: 3rem; }
  .row--input:first-child {
    margin-top: 0; }
  .row--input:last-child {
    margin-bottom: 0; }

.text-row--mini {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }
  .text-row--mini:first-child {
    margin-top: 0; }
  .text-row--mini:last-child {
    margin-bottom: 0; }

.text-row--tiny {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }
  .text-row--tiny:first-child {
    margin-top: 0; }
  .text-row--tiny:last-child {
    margin-bottom: 0; }

.text-row--small {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }
  .text-row--small:first-child {
    margin-top: 0; }
  .text-row--small:last-child {
    margin-bottom: 0; }

.text-row {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }
  .text-row:first-child {
    margin-top: 0; }
  .text-row:last-child {
    margin-bottom: 0; }

.text-row--large {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }
  .text-row--large:first-child {
    margin-top: 0; }
  .text-row--large:last-child {
    margin-bottom: 0; }
