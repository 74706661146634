/**
 * helpers.scss
 */
/*
  settings.scss
  ------------------------------------------------------------

  This document is intended to carry variables, function,
  mixins and similar for other components to import.

  Only completely silent objects are allowed in here.
*/
/**
 * Global variables
 */
/*
  transitions.scss
  ------------------------------------------------------------

  Setup timing functions and transition durations
*/
.cf:after {
  content: "";
  clear: both;
  display: table; }

.flat {
  margin: 0 !important; }

.text--bold {
  font-weight: bold; }

.text--lead {
  font-size: 1.25em; }

.text--muted {
  color: #a3acc2; }

.align--left {
  text-align: left; }

.align--center {
  text-align: center; }

.align--right {
  text-align: right; }

.svg--white {
  fill: white; }

.svg--light {
  fill: #9989c8; }

@media (min-width: 1440px) {
  .stretch {
    left: -80px;
    width: calc(100% + 160px);
    max-width: none; }
    :global(.no-csscalc) .stretch {
      left: auto; } }

.container {
  left: -15px;
  width: calc(100% + 30px);
  max-width: none; }
  :global(.no-csscalc) .container {
    left: auto; }
  .container:after {
    content: "";
    clear: both;
    display: table; }

.split {
  width: 50%;
  float: left;
  padding: 80px 0; }
  .split + .split {
    float: right; }
